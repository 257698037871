.card-stack {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  .card-item {
    padding: 18px 20px 20px 20px;
    border-radius: 10px;
    border: 2px solid var(--primary-border);
    background: var(--primary-transparent1);
    display: flex;
    text-align: left;

    align-items: flex-start;
    gap: 20px;
    flex-shrink: 0;

    .card-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      flex: 1 0 0;

      & label {
        font-size: 24px;
        font-weight: 600;
        font-family: var(--font-sans);
      }

      & p {
        font-size: 18px;
        font-weight: 400;
        font-family: var(--font-sans);
      }
    }
  }
}
