.fullPage {
  background-color: var(--primary-transparent1);
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
  height: 100%;
  min-width: 550px;
  gap: 20px;
  max-width: max-content;
  margin: auto;
  padding: 30px;
  border-radius: 10px;
  background-color: white;
  height: fit-content;
  box-shadow: 0px 0px 10px 0px var(--primary-transparent2);

  &__logo {
    width: 100px;
  }

  &__title {
    font-size: 35px;
    font-weight: 700;
    margin-bottom: 30px;
    font-family: var(--font-rounded);
  }

  &__fieldGroup {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 10px;

    label {
      //   color: var(--grey);
    }
  }

  button {
    width: 100%;
  }
}
