.gameCard[id='flash'] {
  background-color: var(--gamemode-flash-background);
  border: 2px solid var(--gamemode-flash-stroke);
}
.gameCard[id='column'] {
  background-color: var(--gamemode-column-background);
  border: 2px solid var(--gamemode-column-stroke);
}

.gameCard {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  border-radius: 10px;
  gap: 20px;

  &__selectFlash {
    background-color: white;
    border-color: var(--gamemode-flash-stroke);

    &:focus {
      border-color: var(--gamemode-flash-orange);
    }
  }

  &__selectColumn {
    background-color: white;
    border-color: var(--gamemode-column-stroke);

    &:focus {
      border-color: var(--gamemode-column-green);
    }
  }

  &__headerContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__header {
    display: flex;
    gap: 20px;
    align-items: center;

    p {
      font-size: 28px;
      font-family: var(--font-rounded);
      font-weight: 700;
    }
  }

  &__inputContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  &__inputGroup {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: space-between;

    &__labelGroup {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    &__label {
      font-size: 18px;
      font-weight: 500;
    }

    &__label[id='column'] {
      color: var(--gamemode-column-text);
    }

    &__label[id='flash'] {
      color: var(--gamemode-flash-text);
    }

    &__row {
      display: flex;
      gap: 8px;
      align-items: center;

      svg {
        cursor: pointer;

        &:hover {
          opacity: 0.8;
          transition: all 0.2s ease-in-out;
        }
      }

      input {
        text-align: center;
        width: 70px;
        background-color: var(--white);

        &:focus {
          outline: none;
        }
      }

      input[id='flash'] {
        border: var(--gamemode-flash-stroke) 2px solid;

        &:focus {
          border: var(--gamemode-flash-orange) 2px solid;
        }
      }

      input[id='column'] {
        border: var(--gamemode-column-stroke) 2px solid;

        &:focus {
          border: var(--gamemode-column-green) 2px solid;
        }
      }
    }
  }

  &__buttonContainer {
    display: flex;
    align-items: center;
    width: 40%;

    align-self: flex-end;

    button {
      flex: 1;
    }
  }
}
