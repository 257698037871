.filter-result-row {
  display: flex;
  align-items: center;
  gap: 20px;

  .separator {
    width: 1px;
    height: 30px;
    background-color: var(--primary-border);
    margin: 0 10px;
  }

  .filteringby-row-spacebetween {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    width: 100%;
  }

  .filteringby-row {
    display: flex;
    align-items: center;
    gap: 10px;
    white-space: nowrap;
  }
}

.filter-info {
  color: var(--grey);
}

.input-group {
  position: relative;
}

.loader {
  position: absolute;
  top: 50%;
  right: 0;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 20px;
  height: 20px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
