.gameResults {
  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
      font-size: 50px;
      font-weight: 700;
      font-family: var(--font-rounded);
      margin-bottom: 20px;
    }

    &__score {
      font-size: 30px;
      color: var(--grey);
      margin-bottom: 10px;

      span {
        color: var(--black);
        font-weight: 600;
      }
    }
  }
  &__button {
    display: flex;
    flex: 1;
    height: 72px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 10px;

    p {
      font-size: 28px;
      font-family: var(--font-rounded);
      font-weight: 700;
    }

    &__exit {
      background-color: var(--primary-transparent1);
      border: var(--primary-border) 2px solid;
      p {
        color: var(--primary);
      }
    }

    &__play {
      background-color: var(--primary);
      border: transparent 2px solid;
      p {
        color: var(--white);
      }
    }
  }

  &__buttonContainer {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
}
