.SnackbarContent-root {
  box-shadow: none !important;
  border-radius: 0 !important;
  align-items: center;
  flex-wrap: nowrap !important;
}

.SnackbarItem-action {
  padding-left: 5px !important;
  cursor: pointer;
}

#notistack-snackbar {
  font-family: var(--font-sans);
  font-size: 18px;
  font-weight: 500;

  color: var(--black) !important;

  svg {
    fill: var(--black) !important;
  }
}

.notistack-MuiContent {
  background-color: transparent;
  box-shadow: none;
  border-radius: 6px !important;
}

.notistack-MuiContent-success {
  background-color: var(--green_bg_flat) !important;
  border: solid 2px var(--green) !important;
  stroke: var(--green_text) !important;
}

.notistack-MuiContent-info {
  background-color: var(--blue_bg_flat);
  border: solid 2px var(--blue) !important;
  stroke: var(--blue_text) !important;
}

.notistack-MuiContent-error {
  background-color: var(--red_bg_flat) !important;
  border: solid 2px var(--red) !important;
  stroke: var(--red_text) !important;
}

.notistack-MuiContent-warning {
  background-color: var(--yellow_bg_flat) !important;
  border: solid 2px var(--yellow) !important;
  stroke: var(--yellow_text) !important;
}
