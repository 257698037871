.button {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;
  padding: 10px 15px;
  border-radius: 6px;
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;
  &:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }
}

.light {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;
  padding: 10px 15px;

  color: var(--primary);
  background: var(--primary-transparent1);
  border: var(--primary-border) 2px solid;
}
.primary {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;
  padding: 10px 15px;

  color: var(--white);
  background: var(--primary);
  border: var(--primary) 2px solid;
}
.secondary {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;
  padding: 10px 15px;

  color: var(--black);
  background: var(--black-transparent1);
  border: var(--black-transparent1) 2px solid;
}

.green {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;
  padding: 10px 15px;

  color: var(--white);
  background: var(--gamemode-column-green);
  border: transparent 2px solid;
}

.orange {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;
  padding: 10px 15px;

  color: var(--white);
  background: var(--gamemode-flash-orange);
  border: transparent 2px solid;
}
