.questionArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &__question {
    font-size: 80px;
    font-family: var(--font-rounded);
    font-weight: 700;
  }
}
