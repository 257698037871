.answerFeedback {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__small {
    font-size: 40px;
    font-family: var(--font-rounded);
    font-weight: 700;
    color: var(--black-transparent3);
  }

  &__expressionRow {
    display: flex;
    gap: 20px;
    align-items: center;

    img {
      width: 300px;
      height: 300px;
    }

    &__redCircle {
      width: 100px;
      height: 100px;
      margin: 20px 0;
      border-radius: 50%;
      background-color: var(--red_text);
    }

    &__greenCircle {
      width: 100px;
      height: 100px;
      margin: 20px 0;
      border-radius: 50%;
      background-color: var(--zelyoni);
    }
  }
}
