.sidebar {
  display: flex;
  min-width: 280px;
  padding: 35px 16px 16px 16px;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  gap: 20px;

  border-right: 2px solid var(--black-transparent1);
  background: var(--white);
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  // align-self: stretch;

  &__item,
  &__item_logout {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 6px 10px;
    user-select: none;
    cursor: pointer;
    border-radius: 10px;
    border: 2px solid transparent;

    p {
      font-family: var(--font-rounded);
      font-size: 20px;
      font-weight: 700;
    }

    &:hover {
      background-color: var(--primary-transparent1);
    }
  }

  &__item {
    &__number {
      width: 35px;
      height: 35px;
      font-size: 18px;
      font-weight: 700;
      color: var(--primary);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      background-color: var(--primary-transparent2);
    }

    &__active {
      background-color: var(--primary-transparent1);
      border: 2px solid var(--primary);
    }
  }

  &__item_logout {
    border: 2px solid var(--black-transparent2);
    p {
      color: var(--grey);
    }

    svg {
      fill: var(--grey);
    }

    &:hover {
      border: 2px solid var(--red);
      background-color: var(--red_bg_flat);
      p {
        color: var(--red_text);
      }

      svg {
        fill: var(--red_text);
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;

  justify-content: flex-end;
  gap: 20px;
  align-items: flex-end;
  user-select: none;

  &__starBlock {
    padding: 20px 10px 10px 10px;
    display: flex;
    align-self: stretch;
    border-radius: 10px;
    border: 2px solid var(--black-transparent1);
    gap: 10px;
    justify-content: space-between;

    &__starImg {
      img {
        width: 100px;
        height: 100px;
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      &__title {
        font-size: 20px;
      }

      &__subtitle {
        font-family: var(--font-rounded);
        font-size: 70px;
        font-weight: 700;
      }
    }
  }
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__profileHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &__contentMain {
      display: flex;
      align-items: center;
      gap: 10px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }

      p {
        font-family: var(--font-rounded);
        font-size: 28px;
        font-weight: 700;
      }
    }

    &__toggleButton {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50px;
      height: 50px;
      border-radius: 4px;
      border: 2px solid var(--black-transparent2);
    }
  }

  &__profileContentList {
    display: flex;
    flex-direction: column;
    gap: 6px;

    &__item {
      display: flex;
      align-items: center;
      gap: 10px;

      p {
        color: var(--grey);
        font-size: 14px;
      }
    }
  }
}
