:root {
  --black: #042a36;
  --black-transparent1: rgba(4, 42, 54, 0.05);
  --black-transparent2: rgba(4, 42, 54, 0.15);
  --black-transparent3: rgba(4, 42, 54, 0.5);
  --primary: #199fc9;
  --primary-transparent1: rgba(25, 159, 201, 0.05);
  --primary-transparent2: rgba(25, 159, 201, 0.15);
  --primary-border: #ddeaef;
  --grey: #a6a6a6;
  --grey-transparent1: rgba(166, 166, 166, 0.05);
  --grey-transparent2: rgba(166, 166, 166, 0.15);

  --gamemode-column-background: rgba(15, 124, 19, 0.05);
  --gamemode-column-stroke: rgba(15, 124, 19, 0.15);
  --gamemode-column-text: rgba(15, 124, 19, 0.5);
  --gamemode-column-green: #0f7c13;

  --gamemode-flash-background: rgba(195, 83, 20, 0.05);
  --gamemode-flash-stroke: rgba(195, 83, 20, 0.15);
  --gamemode-flash-text: rgba(195, 83, 20, 0.5);
  --gamemode-flash-orange: #c35314;

  /* Semantic Colors */
  --red: #e87a7a;
  --red_bg_flat: #ffe3e4;
  --red_bg: #e87a7a20;
  --red_text: #e60f0f;
  --blue: #7ab1e8;
  --blue_bg_flat: #e2effb;
  --blue_bg: #7ab1e820;
  --blue_text: #074f97;
  --yellow: #ddb037;
  --yellow_bg_flat: #faefd4;
  --yellow_bg: #ddb03720;
  --yellow_text: #98761a;
  --green: #74c98b;
  --green_bg_flat: #dff5e7;
  --green_bg: #74c98b20;
  --green_text: #178b36;

  --zelyoni: #3acb40;

  --font-sans: 'Inter', sans-serif;
  --font-rounded: 'Nunito', sans-serif;

  --white: #ffffff;
}

* {
  box-sizing: border-box;
  color: var(--black);
}

body {
  font-family: var(--font-sans);
  font-size: 18px;
}

.App {
  display: flex;
  max-width: 1020px;
  width: 100%;
  padding: 20px 20px;
  flex-direction: column;
  align-items: stretch;
  gap: 80px;
  margin: 0 auto;
}

input,
select {
  background-color: var(--primary-transparent1);
  border: var(--primary-border) 2px solid;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  height: 40px;

  &:disabled {
    color: var(--grey);
    background-color: var(--grey-transparent1);
    border: var(--grey-transparent2) 2px solid;
    cursor: not-allowed;
  }

  &:focus {
    outline: none;
    border: var(--primary) 2px solid;
  }

  // &.error {
  //   background-color: var(--red_bg);
  //   border: var(--red) 2px solid;
  //   color: var(--red_text);
  // }
}

button {
  border: 0;
  background: transparent;
  cursor: pointer;
  padding: 0;

  &:hover {
    opacity: 0.8;
    transition: all 0.2s ease-in-out;
  }
}

button.light {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;

  padding: 10px 15px;
  color: var(--primary);
  background: var(--primary-transparent1);

  border-radius: 6px;
  border: var(--primary-border) 2px solid;
}

button.primary {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;

  padding: 10px 15px;
  color: var(--white);
  background: var(--primary);

  border-radius: 6px;
  border: var(--primary) 2px solid;
}

button.secondary {
  font-family: var(--font-rounded);
  font-weight: 700;
  font-size: 20px;

  padding: 10px 15px;
  color: var(--black);
  background: var(--black-transparent1);

  border-radius: 6px;
  border: var(--black-transparent1) 2px solid;
}

button:disabled {
  opacity: 0.3;
  cursor: not-allowed;
}

.section-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.section-header {
  label {
    font-size: 30px;
    font-weight: 600;
  }

  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

@keyframes loading {
  0% {
    background-color: var(--primary-border);
  }
  50% {
    background-color: var(--primary-transparent);
  }
  100% {
    background-color: var(--primary-border);
  }
}

.skeleton {
  animation: loading 1.5s infinite;
}
