.pill {
  display: flex;
  align-items: center;
  font-weight: 700;
  height: 24px;
  font-size: 12px;
  gap: 5px;
  text-transform: uppercase;

  border-radius: 30px;

  padding: 4px 20px 4px 15px;
  width: fit-content;
}

.primary {
  color: var(--primary);
  background: var(--primary-transparent2);
  border: var(--primary) 1px solid;
}
.warn {
  color: var(--yellow);
  background: var(--yellow_bg_flat);
  border: var(--yellow) 1px solid;
}

.danger {
  color: var(--red);
  background: var(--red_bg_flat);
  border: var(--red) 1px solid;
}
