.gameWindow {
  display: flex;
  max-width: 1020px;
  width: 100%;
  padding: 20px 20px 60px 20px;
  flex: 1;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
  justify-content: space-between;

  &__getReadyText {
    font-size: 120px;
    font-family: var(--font-rounded);
    font-weight: 700;

    text-align: center;
  }
  &__getReadyText[id='flash'] {
    color: var(--gamemode-flash-orange);
  }
  &__getReadyText[id='column'] {
    color: var(--gamemode-column-green);
  }
}

.cardWrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;

  &__student > div {
    background-color: white !important;
  }

  &__teacher > div {
    background-color: inherit;
  }
}

@keyframes shrink {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}

.countdownCircle {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  border-radius: 50%;
  background-color: var(--black-transparent3);
  animation: shrink 2s ease-in forwards;
  place-self: center;
}
