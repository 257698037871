.homework-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    box-sizing: border-box;
    border-bottom: 2px solid var(--primary-border);
    font-weight: 600;

    th {
      padding: 16px 10px;
    }

    th:nth-child(1) {
      width: 13%;
    }

    th:nth-child(2) {
      width: 25%;
    }

    th:nth-child(3) {
      width: 22%;
    }

    th:nth-child(4) {
      width: 15%;
    }

    th:nth-child(5) {
      width: 15%;
    }

    th:nth-child(6) {
      width: 10%;
    }
  }

  tbody {
    tr.table-row {
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .date-td {
    position: relative;
    font-size: 12px;
    white-space: pre-line;
    // text-align: center;

    height: 14px;
    line-height: 16px;

    span {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 10px;
      right: 0;
      align-self: center;
    }
  }

  th,
  td {
    text-align: left;
    padding: 25px 10px;
    vertical-align: middle;
    font-size: 16px;
  }

  td.Details {
    font-weight: 600;
    color: var(--primary);
  }

  td.expiresIn.Needs.Review {
    color: var(--grey);
  }

  td.status {
    &::before {
      content: '';
      display: inline-block;
      height: 10px;
      width: 10px;
      margin-right: 5px;
      border-radius: 50%;
      margin-right: 10px;
      margin-bottom: 1px;
    }
  }

  td.status.In.Progress {
    &::before {
      background-color: var(--primary);
    }
  }

  td.status.Reviewed {
    &::before {
      background-color: var(--zelyoni);
    }
  }

  td.status.Needs.Review {
    &::before {
      background-color: var(--yellow);
    }
  }

  td.status.Not.Started {
    &::before {
      background-color: var(--grey);
    }
  }

  tr.table-row:nth-child(odd) {
    background-color: var(--primary-transparent1);
  }
}

.skeleton-list {
  margin-top: -20px;

  & :nth-child(even) .react-loading-skeleton.skeleton {
    background-color: rgba(247, 247, 247, 0.282) !important;
  }
}
