.viewport {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 20px;
}

.logo {
  &:hover {
    opacity: 0.5;
  }
}

.links {
  display: flex;
  gap: 45px;
  align-items: center;

  a {
    text-decoration: none;
    padding: 5px;
    border-radius: 5px;
    font-size: 22px;
    font-family: var(--font-rounded);
    font-weight: 700;

    &:hover {
      opacity: 0.5;
    }

    &.activeLink {
      color: var(--primary);
    }
  }
}

.navbarProfile {
  border-radius: 50%;
  background: var(--primary-border);
  width: 60px;
  height: 60px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-family: var(--font-rounded);
  color: var(--black-transparent2);
  user-select: none;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
  }
}
