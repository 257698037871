.inputPanel {
  display: flex;
  gap: 10px;
}

.input {
  flex: 1;
  width: 100%;
  height: 72px;
  border-radius: 10px;
  text-align: center;
  font-size: 28px;
  font-family: var(--font-rounded);
  font-weight: 600;

  &::placeholder {
    color: var(--grey);
  }
}

.button {
  background-color: var(--primary);
  width: 72px;
  height: 72px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
