.user-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;

  thead {
    box-sizing: border-box;
    border-bottom: 2px solid var(--primary-border);
    font-weight: 600;

    th {
      padding: 16px 10px;
    }

    th:nth-child(1) {
      width: 25%;
    }

    th:nth-child(2) {
      width: 25%;
    }

    th:nth-child(3) {
      width: 25%;
    }

    th:nth-child(4) {
      width: 5%;
    }
  }

  tbody {
    tr.table-row {
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  th,
  td {
    text-align: left;
    padding: 20px 10px;
    vertical-align: middle;
    font-size: 16px;
  }

  td.td-name-avatar {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 37px;
      height: 37px;
      border-radius: 50%;
      flex-shrink: 0;
    }
  }

  tr.table-row:nth-child(odd) {
    background-color: var(--primary-transparent1);
  }
}

.skeleton-list {
  margin-top: -20px;

  & :nth-child(even) .react-loading-skeleton.skeleton {
    background-color: rgba(247, 247, 247, 0.282) !important;
  }
}

.sortable-header {
  cursor: pointer;
  position: relative;
  user-select: none;
  opacity: 1;
  transition: all 0.2s ease-in-out;

  &:hover {
    opacity: 0.8;
  }

  &::after {
    content: '';
    position: absolute;

    margin-left: 8px;
    top: 23px;

    border: 5px solid transparent; // Adjust size as needed
    border-top-color: var(--grey); // Triangle color for ascending sort
  }

  &.sorted-asc {
    color: var(--primary);
  }

  &.sorted-desc {
    color: var(--primary);
  }

  &.sorted-asc::after {
    border-top-color: var(--primary); // Triangle color for ascending sort
    border-bottom-color: transparent;
    top: 23px;
    opacity: 1;
  }

  &.sorted-desc::after {
    border-bottom-color: var(--primary); // Triangle color for descending sort
    border-top-color: transparent;
    top: 18px;
    opacity: 1;
  }
}
