.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.gameType-header {
  display: flex;
  gap: 20px;
  align-items: center;

  p {
    font-size: 28px;
    font-family: var(--font-rounded);
    font-weight: 700;
  }
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding-top: 20px;

  &__row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  &__indicator {
    color: var(--grey);
  }
}

.popup {
  position: fixed;
  top: 100px;
  left: 50%;
  transform: translate(-50%, 0%);
  width: 80%;
  max-width: 500px;
  padding: 30px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  max-height: calc(100vh - 200px);
  overflow-y: auto;

  display: flex;
  flex-direction: column;
}

.fields-stack {
  display: flex;
  flex-direction: column;
  gap: 7px;
  padding-top: 20px;
}

.button-footer {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.list-action-button {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 600;
  padding: 5px 0px 5px 10px;
}

.hiddenAssignmentsMessage {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 14px;
  color: var(--grey);
  align-self: center;
  line-height: 1.3em;
}

.separator {
  width: 100%;
  height: 2px;
  background-color: var(--primary-transparent2);
  flex-shrink: 0;
  margin: 20px 0;
}

.avatarImage {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  flex-shrink: 0;
}

.sectionHeader {
  label {
    font-size: 30px;
    font-weight: 600;
  }

  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.assignmentsList {
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  &__wrapper {
    margin: 0 -10px;
    padding: 10px 0;
    gap: 20px;
    background-color: var(--primary-transparent1);
    border-bottom: 1px solid var(--primary-border);

    user-select: none;
    cursor: pointer;
  }

  &__wrapper:hover {
    background-color: var(--primary-transparent2);
  }

  &__item {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // &__right {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-start;
    // }

    // &__left {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-end;
    // }
  }
}

.btnShowHide {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  font-weight: 500;
  color: var(--primary);
  user-select: none;
  cursor: pointer;
}

.smallGreyText {
  font-size: 14px;
  color: var(--grey);
}

.studentAssignmentList {
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    flex-direction: column;

    &__header {
      display: flex;
      flex-direction: row;
      gap: 10px;
      padding: 10px 0;
    }

    &__details {
      display: flex;
      flex-direction: column;
      gap: 3px;
      align-items: flex-start;
      width: 100%;

      &__meta {
        display: flex;
        flex-direction: row;

        gap: 10px;
        align-items: center;
        width: 100%;
        justify-content: space-between;

        &__right {
          display: flex;
          flex-direction: row;
          gap: 3px;
          align-items: center;
        }
      }
    }

    &__bank {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      padding-bottom: 20px;
      row-gap: 4px;
      flex-wrap: wrap;

      &__item {
        display: flex;
        flex-direction: row;
        gap: 6px;
        font-size: 14px;
        align-items: center;
        flex-shrink: 0;
        background-color: var(--black-transparent1);
        padding: 2px 6px;
      }
    }
  }
}

.studentList {
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: flex-start;
}

.settingsList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 16px;
  padding-top: 20px;

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;

    &__iconGroup {
      display: flex;
      align-items: center;
      gap: 5px;
      cursor: pointer;
    }

    &__right {
      &::before {
        content: '';
        display: inline-block;
        height: 10px;
        width: 10px;
        margin-right: 5px;
        border-radius: 50%;
        margin-right: 10px;
        margin-bottom: 1px;
      }
    }

    &__right[id='In Progress'] {
      &::before {
        background-color: var(--primary);
      }
    }

    &__right[id='Not Started'] {
      &::before {
        background-color: var(--grey);
      }
    }

    &__right[id='Needs Review'] {
      &::before {
        background-color: var(--yellow);
      }
    }

    &__right[id='Reviewed'] {
      &::before {
        background-color: var(--zelyoni);
      }
    }
  }
}
