.input-checkbox {
  position: relative;
  min-height: 40px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-transparent1);
  border: var(--primary-border) 2px solid;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.unchecked-label {
  font-size: 10px;
  background-color: var(--white);
  border: 1px solid var(--primary);
  text-wrap: nowrap;
  width: max-content;
  padding: 4px 8px;
  border-radius: 30px;
  box-shadow: 0px 2px 3px var(--black-transparent1);
}

.input-checkbox-dropdown {
  position: absolute;
  background-color: var(--white);
  box-shadow: 0px 5px 10px var(--black-transparent2);
  width: 100%;
  top: 5px;
  left: 5px;
  padding: 5px;
  border-radius: 5px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.input-checkbox-dropdown-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  padding: 0px 3px;
  padding-right: 5px;
  height: 30px;
  user-select: none;
  font-size: 12px;

  &:hover {
    background-color: var(--primary-transparent1);
    border-radius: 3px;
    cursor: pointer;
  }
}

.asterisk {
  color: var(--red_text);
}

.error {
  label {
    color: var(--red_text) !important;
  }

  input,
  select {
    background-color: var(--red_bg);
    border: var(--red) 2px solid;
    color: var(--red_text);
  }
}

.field-flex {
  display: flex;

  justify-content: space-between;
  width: 100%;
  align-items: center;

  gap: 20px;

  .field-with-icon {
    position: relative;

    svg {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }

  .row {
    transition: all 0.2s ease-in-out;
    display: flex;
    gap: 10px;
    align-items: center;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      flex-shrink: 0;
    }
  }

  .row.inactive {
    opacity: 0.5;
  }
}

.field-note {
  font-size: 12px;
  color: var(--grey);
}

.uploadAvatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: var(--primary-transparent2);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: var(--white);
  border: 2px solid var(--primary-border);

  &:hover {
    opacity: 0.8;
  }
}

.avatarGroup {
  display: flex;
  align-items: flex-start;
  gap: 6px;

  .avatar img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
  }

  .avatar-radio {
    display: none;
  }

  .avatar-image {
    opacity: 0.5;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }

  .avatar-radio:checked + label .avatar-image {
    opacity: 1;
  }

  .avatar {
    position: relative;
    width: 40px;
    height: 40px;
  }
}

.error-summary {
  color: var(--red_text);
  font-size: 14px;
  padding-top: 20px;
  text-align: left;
}

.list-action-button {
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: 600;
  padding: 5px 0px 5px 10px;
}
