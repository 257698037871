.content {
  padding: 30px 20px;
  width: 100%;
  // width: calc(100vw - 280px);
}

.sectionWrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-size: 32px;
    font-weight: 700;
    font-family: var(--font-rounded);
    color: var(--black-transparent3);
  }
}

.cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;

  &__item {
    display: flex;
    flex-direction: column;
    gap: 30px;
    justify-content: space-between;
    min-height: 360px;
    padding: 20px;
    border-radius: 10px;
    background-color: white;
    border: var(--black-transparent1) 2px solid;

    &__top {
      display: flex;
      flex-direction: column;
      gap: 30px;
    }

    &__header {
      display: flex;
      flex-direction: row;
      gap: 16px;

      h1 {
        font-size: 24px;
        font-weight: 700;
        font-family: var(--font-rounded);
      }

      &__text {
        display: flex;
        flex-direction: column;
        gap: 3px;
      }
    }
  }
}

.settings {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__row {
    font-size: 14px;
    &__group {
      display: flex;
      flex-direction: row;
      gap: 5px;
      align-items: center;
    }
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
  }
}

.bank {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  row-gap: 4px;
  flex-wrap: wrap;
  margin-top: 10px;

  &__item {
    display: flex;
    flex-direction: row;
    gap: 6px;
    font-size: 14px;
    align-items: center;
    flex-shrink: 0;
    background-color: var(--black-transparent1);
    padding: 2px 6px;
  }
}

.smallGreyText {
  font-size: 14px;
  color: var(--grey);
}

.listFrame {
  padding: 20px;
  border-radius: 10px;
  background-color: white;
  border: var(--black-transparent1) 2px solid;
  display: flex;
  flex-direction: column;
  gap: 20px;

  &__title {
    font-size: 20px;
    font-weight: 700;
    font-family: var(--font-rounded);
  }

  &__header {
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
}

.right {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;

  &__iconText {
    display: flex;
    flex-direction: row;
    gap: 5px;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 360px;

  &__text {
    font-size: 40px;
    font-weight: 700;
    font-family: var(--font-rounded);

    span {
      color: var(--black-transparent3);
    }
  }

  &__img {
    width: 200px;
    height: 200px;
  }
}
