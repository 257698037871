.controlPanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.exitButton {
  margin-right: 55px; //added margin to make sure the round indicator is centered
}

.roundsIndicator {
  display: flex;
  gap: 10px;
  align-items: center;
  max-width: 600px;
  width: 100%;
  flex-wrap: wrap;
  justify-content: center;
  user-select: none;

  &__item {
    width: 45px;
    height: 45px;
    border-radius: 5px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--black-transparent1);
    font-weight: 600;
    font-family: var(--font-rounded);
    color: var(--grey);

    &__current {
      border: 2px solid var(--black);
      color: var(--black);
    }

    &__correct {
      color: var(--white);
      background-color: var(--zelyoni);
    }
    &__incorrect {
      color: var(--white);
      background-color: var(--red_text);
    }
  }
}

.timer {
  display: flex;
  font-size: 16px;

  color: var(--grey);
  min-width: 100px;
  text-align: end;

  p {
    color: var(--grey);
  }
}
