.sidebar {
  display: flex;
  min-width: 82px;
  padding: 35px 16px 16px 16px;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  gap: 20px;

  border-right: 2px solid var(--black-transparent1);
  background: var(--white);
}

.header {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__menuButton,
  &__menuButton_logout,
  &__toggleButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 4px;
    border: 2px solid transparent;
  }

  &__menuButton {
    &:hover {
      background-color: var(--primary-transparent1);
    }

    &__active {
      background-color: var(--primary-transparent1);
      border: 2px solid var(--primary);
    }
  }

  &__toggleButton {
    border: 2px solid var(--black-transparent2);
  }

  &__menuButton_logout {
    border: 2px solid var(--black-transparent2);

    svg {
      fill: var(--grey);
    }

    &:hover {
      border: 2px solid var(--red);
      background-color: var(--red_bg_flat);

      svg {
        fill: var(--red_text);
      }
    }
  }
}

.footer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
