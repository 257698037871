.emptyCard {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: var(--grey);
  background-color: var(--black-transparent1);
  width: 100%;
  border-radius: 8px;
  grid-column: span 3;
  grid-row: span 2;
  // border: 1px solid var(--primary-border);
  p {
    color: var(--grey);
  }
}
