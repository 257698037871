.skView {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.skNav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 90px;
  padding: 0 20px;
}

.skApp {
  display: flex;
  max-width: 1020px;
  width: 100%;
  padding: 20px 20px;
  flex-direction: column;
  align-items: stretch;
  gap: 80px;
  margin: 0 auto;
}

.skHeader {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.skGroupStack {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.skSection {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.skCardRow {
  display: flex;
  flex-direction: row;
  gap: 20px;

  & > span {
    flex: 1;
  }
}

.skNavLinks {
  display: flex;
  gap: 45px;
  align-items: center;
}
