.questionArea {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &__question {
    font-size: 140px;
    font-family: var(--font-rounded);
    font-weight: 700;
  }
}

.colorOrange {
  color: var(--gamemode-flash-orange);
}

.colorGreen {
  color: var(--gamemode-column-green);
}

.colorMain {
  color: var(--primary);
}

.circlesContainer {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: absolute;
  bottom: 200px;
}

.activeCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--black-transparent3);
}

.inactiveCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--black-transparent2);
}
