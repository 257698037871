.homeContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  box-sizing: border-box;
  background-color: var(--primary-transparent1);

  h1 {
    text-align: center;
    font-family: var(--font-rounded);
    font-weight: 600;
    font-size: 40px;
    color: var(--black);
  }
}

.cardContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
}

.styledLink {
  text-decoration: none;
  color: inherit;
  width: 45%; // Slightly less than 50% to add some space between cards
  height: 70vh; // 70% of the viewport height
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 2px solid var(--black-transparent1);
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    border: 2px solid var(--primary);
  }

  h2 {
    font-size: 24px;
    margin-bottom: 15px;
    color: var(--black);
    font-family: var(--font-rounded);
  }

  p {
    text-align: center;
    color: var(--black);
    font-family: var(--font-rounded);
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .cardContainer {
    flex-direction: column;
  }

  .styledLink {
    width: 100%;
    height: 40vh;
    margin-bottom: 20px;
  }
}
